import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import PostShortBlurb from '../components/postShortBlurb';
import PostStyle from '../components/postContent';
export const pageQuery = graphql`{
  allMdx(
    filter: { fileAbsolutePath: { regex: "//posts//" } }
    sort: { fields: [frontmatter___date], order: DESC }
    limit: 2
    skip: 0
  ) {
    edges {
      node {
        id
        excerpt
        frontmatter {
          title
          date
          path
          blurb
          shortBlurb
          tags
          coverImage {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
}`;
export const _frontmatter = {
  "title": "Home",
  "path": "/"
};
const layoutProps = {
  pageQuery,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <>
    <SEO />
    <Layout>
      <div>
        {children}
      </div>
    </Layout>
  </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PostStyle mdxType="PostStyle">
This is just a small personal site mostly containing some long and rambling posts by myself about things that probably don't matter.  
      <p>{`I've been a professional developer for over 5 years, working in the gambling industry creating games for integrated cabinets in C++/DirectX, before moving to online using TS/WebGL.  `}</p>
      <p>{`Like most people, originally it was a way to get into the games industry but I've found that over time I've enjoyed it. I've moved from being a junior dev up to being the Dev manager, over-seeing every game and developer in the company. When I started, that meant just 2 other devs, but my dev team is currently 8 developers strong and continuing to grow, pushing out nearly 20 games a year.  `}</p>
      <p>{`Besides managing my team, the libraries and doing the more complex parts of our code changes, I also deal with the servers (k8s and general docker goodness), CI (gitlab hosted) and other webby things (AWS, auth etc).`}</p>
      <p>{`While I do also do programming in my own time (this website for example), I try to have other, only slightly related hobbies. So I build and fly FPV drones, have rebuilt coffee machinery, built some headphone cables and recently been getting into woodworking.`}</p>
    </PostStyle>
    <div>
  {props.data.allMdx.edges.map(edge => {
        const {
          id,
          excerpt,
          frontmatter: {
            title,
            date,
            path,
            coverImage,
            blurb,
            shortBlurb,
            tags
          }
        } = edge.node;
        return <PostShortBlurb key={id} title={title} date={date} path={path} coverImage={coverImage} tags={tags} shortBlurb={shortBlurb || blurb || excerpt} mdxType="PostShortBlurb" />;
      })}
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      